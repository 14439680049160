import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useFetch } from 'hooks/useFetch'
import { useForm } from 'hooks/useForm'
import { useValidator } from 'hooks/useValidator'
import { InputText } from 'components/Inputs/InputText'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { InputSelect } from 'components/Inputs/InputSelect'
import { useLang } from 'language'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { InputRadios } from 'components/Inputs/InputRadios'
import { InputTextNormal } from 'components/Inputs/InputTextNormal'
import { PLANTA, RULES } from 'models/planta.js'
import { Loader } from 'components/Loader/Loader'

export const ModalPlantaRasant = ({ data, handleClose, parametros, dataradon, reload }) => {

    const locale = useLang();

    const [formPlanta, handleInputChangePlanta, form, resetFormPlanta] = useForm(PLANTA)

    const [fetchPlanta, fetchPlantaData] = useFetch('POST', `/clientes/solicitudes_radon_planta`);

    const [fetchEditarPlanta, fetchEditarPlantaData] = useFetch('GET', `/clientes/solicitudes_radon_planta`)

    const [fetchGuardarEditarPlanta, fetchGuardarEditarPlantaData] = useFetch('PATCH', `/clientes/solicitudes_radon_planta`)

    const [valuesValidPlanta, handleVerifiedValuesPlanta] = useValidator(RULES)

    const [valuesValidPlantaLocal, handleVerifiedValuesPlantaLocal] = useValidator({
        local_habitable: {
            required: {
                isOk: true,
                msgError: [locale('EL_CAMPO_ES_OBLIGATORIO')]
            }
        },
    })

    const [valuesValidPlantaUso, handleVerifiedValuesPlantaUso] = useValidator({
        local_uso: {
            required: {
                isOk: true,
                msgError: [locale('EL_CAMPO_ES_OBLIGATORIO')]
            }
        },
    })

    const handleSubmitAgregarPlanta = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesPlanta(formPlanta)) {

            if (formPlanta.dispone_local === 'Si' && !handleVerifiedValuesPlantaLocal(formPlanta)) return

            if (formPlanta.dispone_local === 'No' && !handleVerifiedValuesPlantaUso(formPlanta)) return

            fetchPlanta({
                body: {
                    ...formPlanta,
                    solicitud_radon_id: dataradon?.id
                }
            })
        }
    }

    useEffect(() => {
        if (fetchPlantaData.data?.status === 'Success') {
            resetFormPlanta()
            handleClose()
            reload()
        }
    }, [fetchPlantaData.data])


    const handleSubmitEditarPlanta = (e) => {
        e.preventDefault()
        if (handleVerifiedValuesPlanta(formPlanta)) {
            fetchGuardarEditarPlanta({
                id: data.id + '/update',
                body: {
                    ...formPlanta
                }
            })
        }
    }

    useEffect(() => {
        if (fetchGuardarEditarPlantaData.data?.status === 'Success') {
            resetFormPlanta()
            handleClose()
            reload()
        }
    }, [fetchGuardarEditarPlantaData.data])


    useEffect(() => {
        if (data?.id) {
            resetFormPlanta()
            fetchEditarPlanta({
                id: data?.id + '/edit'
            })
        }
    }, [data.value])

    useEffect(() => {

        if (fetchEditarPlantaData.data?.data) {

            const muestra = fetchEditarPlantaData.data?.data
            form({ ...PLANTA(muestra), solicitud_radon_id: dataradon.id })
        }

    }, [fetchEditarPlantaData])

    const handleCloseModal = (e) => {
        resetFormPlanta();
        handleClose()
    }

    const renderTitle = () => {

        // if(dataradon.edificio_tipo_edificio === 'unifamiliar'){
        //     return 'Identifica la planta p. ejemplo: Planta sótano -1, planta baja, planta 1ª, etc)';
        // }else if(dataradon.edificio_tipo_edificio === 'plurifamiliar'){
        //     return 'hemos cumplimentado en la solicitud, del Piso o entidad a medir.';
        // }else if(dataradon.edificio_tipo_edificio === 'otros'){
        //     return 'Biblioteca';
        // }

        // if (dataradon.edificio_tipo_edificio === 'otros') {

        //     return locale('P_EJEMPLO_RECINTOS_TRABAJO_AULAS')

        // } else {

        // }
        return locale('P_IDENTIFICA_PLANTA')

    }

    const maxLength = 50;

    const handleChangeLimitInput = (event) => {
        const { value } = event.target;
        if (value.length <= maxLength) {
          handleInputChangePlanta(event);
        }
    };

    return (
        <>
            <Modal show={data.value} onHide={handleCloseModal} size='lg'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'>
                        {data.action === 'CREATE' ? locale('AGREGAR_PLANTA') : locale('EDITAR_PLANTA')}
                    </Modal.Title>
                </Modal.Header>

                {data?.id && fetchEditarPlantaData.loading ? <Loader /> : <>

                    <Modal.Body>
                        <div className="container">
                            <Form onSubmit={handleSubmitAgregarPlanta} className='row'>

                                <Form.Group className="col-12 col-md-6 col-lg-4 mb-3">

                                    <InputSelect
                                        label={locale('SELECCIONE')}
                                        className=''
                                        name='tipo_planta'
                                        values={
                                            parametros.data?.data?.tipo_planta?.map((item, index) => {
                                                return {
                                                    id: item.param_id,
                                                    nombre: item.texto
                                                }
                                            })
                                        }
                                        value={formPlanta.tipo_planta}
                                        onChange={handleInputChangePlanta}
                                        placeholder="tipo_planta"
                                        validation={valuesValidPlanta}
                                        fetchData={fetchPlantaData}
                                    />
                                </Form.Group>

                                <Form.Group className="col-12 col-md-6 col-lg-8 mb-3">
                                    <InputText
                                        label={locale('P_IDENTIFICA_PLANTA')}
                                        className=''
                                        name='nombre'
                                        value={formPlanta.nombre}
                                        onChange={handleChangeLimitInput}
                                        placeholder="planta"
                                        validation={valuesValidPlanta}
                                        fetchData={fetchPlantaData}
                                    />
                                    {/* <small>{renderTitle()}</small> */}
                                  <div className="col-12 text-end">  
                                      <small className={
                                        formPlanta.nombre?.length >= 50 ? 'text-danger' :
                                        formPlanta.nombre?.length >= 40 ? 'text-warning' : 
                                        'text-primary'
                                      }>
                                        {formPlanta.nombre?.length || 0}/{maxLength} {locale('TEST_RADON_TEXT_CARACTERES')}
                                     </small>
                                 </div>
                                </Form.Group>

                                {
                                // dataradon.edificio_tipo_edificio !== 'plurifamiliar' &&
                                    <Form.Group className="col-12 mb-3">
                                        <p className='mb-1'>{locale('TEST_RADON_DISPONE_LOCAL_FORM')}</p>
                                        <InputRadios
                                            name='dispone_local'
                                            className="text-nowrap"
                                            value={formPlanta.dispone_local}
                                            onChange={handleInputChangePlanta}
                                            validation={valuesValidPlanta}
                                            fetchData={fetchPlantaData}
                                            values={[
                                                { id: 'Si', nombre: 'Si' },
                                                { id: 'No', nombre: 'No' },

                                            ]}
                                        />
                                    </Form.Group>
                                }

                                {
                                    formPlanta.dispone_local === 'No' &&
                                    <Form.Group className="col-12 mb-3">
                                        <InputTextNormal
                                            label={locale('TEST_RADON_IDENTIFIQUE_USO_FORM')}
                                            className=''
                                            name='local_uso'
                                            value={formPlanta.local_uso}
                                            onChange={handleInputChangePlanta}
                                            //placeholder={`Aparcament`}
                                            validation={valuesValidPlantaUso}
                                            fetchData={fetchPlantaData}
                                            as="textarea"
                                            rows={2}
                                        />
                                    </Form.Group>
                                }

                                {formPlanta.dispone_local === 'Si' &&
                                    <Form.Group className="col-12 mb-3">
                                        <InputTextNormal
                                            label={locale('TEST_RADON_LOCALES_HABITABLE')}
                                            className=''
                                            name='local_habitable'
                                            value={formPlanta.local_habitable}
                                            onChange={handleInputChangePlanta}
                                            //placeholder={`Sala d'estar, Dormitori, Bany, Despatx, Cuina`}
                                            validation={valuesValidPlantaLocal}
                                            fetchData={fetchPlantaData}
                                            as="textarea"
                                            rows={2}
                                        />
                                    </Form.Group>
                                }

                                <Form.Group className="col-12 col-md-6 col-lg-6 mb-3">
                                    <InputText
                                        label={locale('TEST_RADON_M_PLANTA_FORM')}
                                        className=''
                                        type="number"
                                        name='m_planta_habitable'
                                        value={formPlanta.m_planta_habitable}
                                        onChange={handleInputChangePlanta}
                                        placeholder="m2_planta"
                                        validation={valuesValidPlanta}
                                        fetchData={fetchPlantaData}
                                        min={0}
                                    />
                                </Form.Group>

                                <Form.Group className="col-12 col-md-6 col-lg-6 mb-3">
                                    <InputText
                                        label={locale('TEST_RADON_ALTURA_PLANTA_FORM')}
                                        className=''
                                        type="number"
                                        name='altura_planta_m'
                                        value={formPlanta.altura_planta_m}
                                        onChange={handleInputChangePlanta}
                                        placeholder="altura_planta"
                                        validation={valuesValidPlanta}
                                        fetchData={fetchPlantaData}
                                        min={0}
                                    />
                                </Form.Group>

                                {/*
                                formPlanta.m_planta_habitable > 1000 &&
                                <div className="col-12">
                                    <InputRadios
                                        name='tipo_zona_muestreo'
                                        onChange={handleInputChangePlanta}
                                        // validation={valuesValidEditarMuestra}
                                        // fetchData={fetchEditarMuestraData}
                                        values={[
                                            { id: '0', nombre: `Compartimentado` },
                                            { id: '1', nombre: `Sin compartimentar` },
                                        ]}
                                        value={formPlanta.tipo_zona_muestreo}
                                    />
                                </div>
                            */}

                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='border-0 justify-content-between'>
                        <Button variant="default" onClick={handleCloseModal}>
                            {locale('TEST_RADON_MODAL_BTN_CERRAR')}
                        </Button>
                        {
                            data?.id ?
                                <ButtonSpinner
                                    variant="primary-radon" type="submit" className="ms-2"
                                    handleSubmit={handleSubmitEditarPlanta} fetchDataLoading={[fetchGuardarEditarPlantaData.loading]}
                                    value={locale('EDITAR_PLANTA')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                />
                                :
                                <ButtonSpinner
                                    variant="primary-radon" type="submit" className="ms-2"
                                    handleSubmit={handleSubmitAgregarPlanta} fetchDataLoading={[fetchPlantaData.loading]}
                                    value={locale('AGREGAR_PLANTA')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                />
                        }
                    </Modal.Footer>

                </>
                }
            </Modal>
        </>
    )
}
