import React, { useEffect, useState, useContext } from "react";
import { PlantasRasants } from "../PlantasRasants/PlantasRasants";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { InputTextNormal } from "components/Inputs/InputTextNormal";
import { ButtonSpinner } from "components/ButtonSpinner/ButtonSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faSave, faSync } from "@fortawesome/free-solid-svg-icons";
import { useFetch } from "hooks/useFetch";
import { InputSelect } from "components/Inputs/InputSelect";
import { InputText } from "components/Inputs/InputText";
import { AuthContext } from "auth/AuthContext";
import { useLang } from 'language';
import { useValidator } from 'hooks/useValidator';

export const DeterminacionDetectores = (props) => {
  const locale = useLang();
  const {
    user: { lang },
  } = useContext(AuthContext);

  const { formTestRadon, handleInputFormTestRadon, fetchSolicitudRadonGeneralData, handleFormChangeEditar, fetchSentData, handleClickGuardarBorrador, hanleClickCambiarSiguiente, setIndexFocusWizard } = props;
  const [totalSensores, setTotaSensores] = useState(0);
  const [fetchPeticionario, fetchDataPeticionario] = useFetch('GET', `/clientes/solicitudes_radon/datos_peticionario`);
  const [fetchParametros, fetchParametrosData] = useFetch("POST", `/parametros_generales`);

  const params = [
    {
      param: "actua_como",
      locale: String(lang).toLowerCase(),
      active: true,
    },
    {
      param: "tipo_planta",
      locale: String(lang).toLowerCase(),
      active: true,
    },
  ];

  const copiarDatosPeticionario = (e) => {
    if (e) {
      e.preventDefault()
    }
    fetchPeticionario();
  }

  useEffect(() => {
    if (fetchDataPeticionario.data?.data) {
      const datos = fetchDataPeticionario.data.data;
      handleFormChangeEditar({
        ...formTestRadon,
        acreditador_nif: datos.usuario_documento || '',
        acreditador_nombre: datos.usuario_nombre || '',
        acreditador_apellido: datos.usuario_apellidos || '',
        acreditador_email: datos.usuario_email || '',
        acreditador_telefono: datos.usuario_telefono || ''
      });
    }
  }, [fetchDataPeticionario.data])

  useEffect(() => {
    fetchParametros({
      body: params,
    });
  }, []);

  const [active, setactive] = useState(-1);

  const maxLength = 50;
  const handleChangeLimitInput = (event) => {
      const { value } = event.target;

      if (value.length > maxLength) {
          event.target.value = value.substring(0, maxLength);
      }
      handleInputFormTestRadon(event);
  };

  const [validAcreditador, handlevalidAcreditador] = useValidator({
    acreditador_nif: {
      required: {
        isOk: true,
        msgError: [locale('V_OBLIGATORIO_CAMPO')]
      }
    },
    acreditador_nombre: {
      required: {
        isOk: true,
        msgError: [locale('V_OBLIGATORIO_CAMPO')]
      }
    },
    acreditador_apellido: {
      required: {
        isOk: true,
        msgError: [locale('V_OBLIGATORIO_CAMPO')]
      }
    },
    acreditador_email: {
      required: {
        isOk: true,
        msgError: [locale('V_OBLIGATORIO_CAMPO')]
      }
    },
    acreditador_telefono: {
      required: {
        isOk: true,
        msgError: [locale('V_OBLIGATORIO_CAMPO')]
      }
    },
    acreditador_actua_como: {
      required: {
        isOk: true,
        msgError: [locale('V_OBLIGATORIO_CAMPO')]
      }
    },
    acreditador_titulo: {
      required: {
        isOk: true,
        msgError: [locale('V_OBLIGATORIO_CAMPO')]
      }
    },
  })

  const handleValidSubmit = () => {
    if(formTestRadon.tipo_solicitud === 'profesional'){
      if (handlevalidAcreditador(formTestRadon)) {
         handleClickGuardarBorrador()
         setactive(2)
         setIndexFocusWizard(2)
      }
    }else{
         setactive(2)
         setIndexFocusWizard(2)
    }
  }

  return (
    <>
      <div className='container pb-0 mt-3'>
        <div className="row">

          <div className="col-12">
            <h5 className='mb-2 pb-4 text-center'>{locale('TEST_RADON_WIZAR_NIVEL2')}</h5>
          </div>

          {formTestRadon.tipo_solicitud === 'profesional' && <>
            <div className="col-12 mb-1">
              <fieldset className="border p-2">
                <legend className="w-auto text-left">{locale('TEST_RADON_ACREDITADOR')}</legend>
                <div className="row">
                  <div className="col-lg-12 mb-3 text-end">
                    <ButtonSpinner
                      variant="primary-radon" type="button" className="ms-3 px-3" size='sm'
                      handleSubmit={(e) => copiarDatosPeticionario(e)} fetchDataLoading={[fetchDataPeticionario.loading]}
                      value={locale('RECUPERAR_DATOS_PETICIONARIO')} icon={<FontAwesomeIcon icon={faSync} className="me-1" />}
                    />
                  </div>

                  <Form.Group className="mb-3 col-md-6 col-lg-4">
                    <InputText
                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                      label='NIF/NIE *'
                      className=''
                      name='acreditador_nif'
                      value={formTestRadon.acreditador_nif}
                      onChange={handleInputFormTestRadon}
                      placeholder="acreditador_nif"
                      fetchData={fetchSentData}
                      validation={validAcreditador}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-6 col-lg-4">
                    <InputText
                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                      label={locale('TEST_RADON_NOMBRE')}
                      className=''
                      name='acreditador_nombre'
                      value={formTestRadon.acreditador_nombre}
                      onChange={handleInputFormTestRadon}
                      placeholder="acreditador_nombre"
                      fetchData={fetchSentData}
                      validation={validAcreditador}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-6 col-lg-4">
                    <InputText
                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                      label={locale('TEST_RADON_APELLIDO')}
                      className=''
                      name='acreditador_apellido'
                      value={formTestRadon.acreditador_apellido}
                      onChange={handleInputFormTestRadon}
                      placeholder="acreditador_apellido"
                      fetchData={fetchSentData}
                      validation={validAcreditador}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-6 col-lg-4">
                    <InputText
                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                      label={locale('TEST_RADON_CORREO')}
                      className=''
                      name='acreditador_email'
                      value={formTestRadon.acreditador_email}
                      onChange={handleInputFormTestRadon}
                      placeholder="peticionario_email"
                      fetchData={fetchSentData}
                      validation={validAcreditador}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-6 col-lg-4">
                    <InputText
                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                      label={locale('TEST_RADON_TELEFONO')}
                      className=''
                      name='acreditador_telefono'
                      value={formTestRadon.acreditador_telefono}
                      onChange={handleInputFormTestRadon}
                      placeholder="peticionario_telefono"
                      fetchData={fetchSentData}
                      validation={validAcreditador}
                    />
                  </Form.Group>
                </div>
              </fieldset>
            </div>

            <div className="col-12 mb-1">
              <fieldset className="border p-2">
                <legend className="w-auto text-left">{locale('TEST_RADON_ACTUA')}</legend>
                <div className="row">
                  <Form.Group className="col-md-6 col-lg-4 mb-3">
                    <InputSelect
                      disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                      label={locale('TEST_RADON_ACTUA') + ' *'}
                      className=''
                      name='acreditador_actua_como'
                      values={
                        fetchParametrosData.data?.data?.actua_como?.map((item, index) => {
                          return {
                            id: item.param_id,
                            nombre: item.texto
                          }
                        })
                      }
                      value={formTestRadon.acreditador_actua_como}
                      onChange={handleInputFormTestRadon}
                      placeholder="acreditador_actua_como"
                      fetchData={fetchSentData}
                      validation={validAcreditador}
                    />
                  </Form.Group>

                  {formTestRadon.acreditador_actua_como === 'projectista' || formTestRadon.acreditador_actua_como === 'direccio_facultativa' ?
                    <>
                      <Form.Group className="col-md-6 col-lg-4 mb-3">
                        <InputText
                          disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                          label={locale('TEST_RADON_TITULO')}
                          className=''
                          name='acreditador_titulo'
                          value={formTestRadon.acreditador_titulo}
                          onChange={handleInputFormTestRadon}
                          placeholder="acreditador_titulo"
                          fetchData={fetchSentData}
                          validation={validAcreditador}
                        />
                      </Form.Group>

                      <Form.Group className="col-md-6 col-lg-4 mb-3">
                        <InputText
                          disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                          label={locale('TEST_RADON_NUMERO_COLEGIADO')}
                          className=''
                          name='acreditador_numero_colegiado'
                          value={formTestRadon.acreditador_numero_colegiado}
                          onChange={handleInputFormTestRadon}
                          placeholder="acreditador_numero_colegiado"
                          fetchData={fetchSentData}
                        />
                      </Form.Group>
                    </>
                    :
                    <>
                    </>
                  }

                  {formTestRadon.acreditador_actua_como === 'entitat_control' &&

                    <Form.Group className="col-md-6 col-lg-4 mb-3">
                      <InputText
                        disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                        label={locale('TEST_RADON_ENTIDAD')}
                        className=''
                        name='acreditador_entidad'
                        value={formTestRadon.acreditador_entidad}
                        onChange={handleInputFormTestRadon}
                        placeholder="acreditador_entidad"
                      //fetchData={fetchSentData}
                      />
                    </Form.Group>
                  }

                </div>
              </fieldset>
            </div>
          </>
          }

          <div className="col-12 mb-1">
            <PlantasRasants
              formTestRadon={formTestRadon}
              parametros={fetchParametrosData}
              setTotaSensores={setTotaSensores} />
          </div>

          <Form.Group className="mb-3 col-12">

            <fieldset className="border p-2">
              <legend className="w-auto text-left">{locale('TEST_RADON_ANOTACIONES')}</legend>

              <InputTextNormal
                as="textarea"
                disabled={formTestRadon.estado !== 'radon_estado_borrador' ? true : false}
                // label=''
                placeholder=""
                className=''
                // style={{ height: '100px' }}
                rows={3}
                onChange={handleChangeLimitInput}
                name='observacion' //anotaciones
                value={formTestRadon.observacion}
              // validation={valuesValidEditarMuestra}
              />
              <div className="col-12 text-end">  
                    <small className={
                      formTestRadon.observacion?.length >= 50 ? 'text-danger' :
                      formTestRadon.observacion?.length >= 40 ? 'text-warning' : 
                      'text-primary'
                    }>
                      {formTestRadon.observacion?.length || 0}/{maxLength} {locale('TEST_RADON_TEXT_CARACTERES')}
                   </small>
               </div>
            </fieldset>
          </Form.Group>

          <div className="col-12 mt-2">
            <div className="d-flex flex-nowrap justify-content-between align-items-center">
              <div className='m-0'>
                <ButtonSpinner
                  variant="outline-primary-radon" type="submit" className="px-3"
                  handleSubmit={() => {
                    setactive(0)
                    setIndexFocusWizard(0)
                  }} fetchDataLoading={[fetchSentData.loading && active === 0]}
                  value={locale('TEST_RADON_VOLVER')} icon={<FontAwesomeIcon icon={faArrowLeft} className="me-1" />}
                />
              </div>
              <div className='m-0'>
                <ButtonSpinner
                  variant="primary-radon" type="submit" className="ms-3 px-3"
                  handleSubmit={() => {
                    setactive(1)
                    handleClickGuardarBorrador()
                  }} fetchDataLoading={[fetchSentData.loading && active === 1]}
                  value={locale('TEST_RADON_BORRADOR')} icon={<FontAwesomeIcon icon={faSave} />}
                />
                <OverlayTrigger overlay={<Tooltip className={totalSensores === 0 ? 'd-inline-block' : 'd-none'} id={`tooltip-continuar-sensores`}>{locale('P_CONTINUAR_NECESARIO_ALMENOS_1_SENSOR')} </Tooltip>}>
                  <span className="d-inline-block">

                    <ButtonSpinner
                      disabled={totalSensores === 0 ? true : false}
                      variant="outline-primary-radon" type="submit" className="ms-3 px-3"
                      handleSubmit={handleValidSubmit} fetchDataLoading={[fetchSentData.loading && active === 2]}
                      value={locale('TEST_RADON_CONTINUAR')} iconRight={<FontAwesomeIcon icon={faArrowRight} className="ms-1" />}
                    />
                  </span>

                </OverlayTrigger>

              </div>
            </div>

          </div>

        </div>
      </div>
    </>
  );
};
